@import 'styles/includes.scss';

.Cards {
    $root: &;
    @include media(m) {
        margin-bottom: 56px;
    }

    &__Header {
        @extend %container;
    }

    &__Heading {
        @extend %h2;
        margin-bottom: 12px;
    }

    &__Preamble {
        @extend %rich-preamble;
        margin-bottom: 18px;
        max-width: 720px;

        @include media(m) {
            margin-bottom: 28px;
        }
    }

    &__Container {
        @extend %container;
        display: flex;
        flex-direction: column;

        @include media(m) {
            flex-direction: row;
        }
    }

    &__Card {
        position: relative;
        width: 100%;
        flex: 1 0 auto;
        margin-bottom: 16px;
        text-align: left;
        background: $colorGrey100;
        display: flex;
        flex-direction: column;

        .ArticlePage &,
        .LandingPage & {
            text-align: center;
        }

        &--Centered {
            text-align: center;
        }

        @include media(m) {
            margin-bottom: 0;

            &:nth-child(2n) {
                margin-left: 24px;
            }
        }

        &--Half {
            @include media(m) {
                width: calc(50% - 24px);
            }
        }
    }

    &__Link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        &:focus {
            outline: none;
        }
    }

    &__Link:hover ~ &__Content {
        background: rgba(0, 0, 0, 0.06);
    }

    &__Link:focus ~ &__Content {
        background: rgba(0, 0, 0, 0.12);
    }

    &__Image {
        position: relative;
        height: 192px;

        @include media(m) {
            height: 240px;

            #{$root}__Card--Large & {
                height: 400px;
            }
        }

        @include media(l) {
            height: 320px;

            #{$root}__Card--Large & {
                height: 640px;
            }
        }
    }

    &__Content {
        @extend %body-copy;
        text-align: left;
        padding: 24px;
        transition: background-color $duration $ease;
        display: flex;
        flex-direction: column;

        .ArticlePage &,
        .LandingPage & {
            text-align: center;
        }

        #{$root}__Card--Centered & {
            text-align: center;
        }

        line-height: 1.5;

        @include media(m) {
            line-height: 1.57;
        }

        @include media(l) {
            padding: 26px 32px;
        }
    }

    &__Title {
        margin-bottom: 8px;
        .ArticlePage &,
        .LandingPage & {
            text-align: center;
        }

        @include media(m) {
            margin-bottom: 0;
        }

        #{$root}__Card--Centered & {
            text-align: center;
        }

        #{$root}__Card--Half & {
            @extend %h3;
        }

        #{$root}__Card--Large & {
            @extend %h2;
        }
    }

    &__Text {
        margin-bottom: 12px;
    }

    &__Button {
        display: block;
        position: relative;
        margin: auto 24px 24px;

        @include media(l) {
            margin: auto 32px 24px;
        }
    }
}

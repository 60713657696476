@import 'styles/includes.scss';

.CampaignCards {
    .Cards {
        margin-bottom: 0;
    }

    .Cards__Card {
        &:last-child {
            .Cards__Button {
                margin-bottom: 0;
            }
        }
    }

    .Cards__Card--Large .Cards__Image {
        @include media(m) {
            height: 450px;
        }
        @include media(l) {
            height: 700px;
        }
    }

    .Cards__Container {
        @include media(m) {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
            gap: 20px;
        }
    }

    .Cards__Card--Half {
        @include media(m) {
            width: 100%;
            &:nth-child(2n) {
                margin-left: 0;
            }
        }
    }

    .Cards__Content,
    .Cards__Button {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        margin-left: 0;
        margin-right: 0;

        @include media(l) {
            margin-bottom: 0;
        }
    }

    .Cards__Title,
    .Cards__Content {
        color: inherit;
    }

    .Cards__Image {
        height: 300px;

        @include media(l) {
            height: 400px;
        }
    }

    .Cards__Button .Button {
        background-color: #fff;
        border-width: 1px;
        border-style: solid;
        border-color: $colorBlue;
        color: $colorBlue;
        font-weight: 400;
        padding: 8px 24px 8px 16px;
        margin-top: 16px;
    }

    &.CampaignSection {
        &--BlackOnWhite .Cards__Card {
            background-color: #fff;

            .Button__Icon--Arrow {
                background-image: url('#{$basepath}svg/icon-arrow-blue.svg');
            }
        }

        &--BlackOnYellow .Cards__Card {
            &,
            .Button {
                background-color: $colorPaleYellow;
            }

            .Button__Icon--Arrow {
                background-image: url('#{$basepath}svg/icon-arrow-blue.svg');
            }
        }

        &--WhiteOnBlue .Cards__Card {
            background-color: $colorDawnBlue;

            .Button {
                background-color: $colorDawnBlue;
                border-color: $colorPaleYellow;
                color: $colorPaleYellow;
            }

            .Button__Icon--Arrow {
                background-image: url('#{$basepath}svg/icon-arrow-yellow.svg');
            }
        }
    }
}
